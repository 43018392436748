import { state } from '@/main';

export async function fetchOwnedMonsters() {
    const query = `
                {
                  user(id: "${state.ethUser.toLowerCase()}") {
                    id
                    monsterSatoshibles(first: 1000) {
                      id
                    }
                  }
                }`;

    const data = await fetch(state.currentProject.subgraphUrl, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({query})
    }).catch(err => console.error(err)).then(res => res.json());

    if (data?.errors?.length) {
        console.error(`Error fetching owned sats from subgraph: ${data.errors[0].message}`);
        return [];
    }

    return data?.data?.user?.monsterSatoshibles?.map(s => parseInt(s.id)).sort((a, b) => a - b) || [];
}
