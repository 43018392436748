<template>
  <div class="wallet">
    <div v-if="state.isLoggedIn" @click="open" class="wallet-content">
      <span class="item">{{ ethAddress }}</span>
      <span class="item">{{ stxAddress }}</span>
      <span class="user">
          <Icon name="user" />
      </span>
    </div>
    <div v-else>
      <button @click="open" class="button primary">Connect</button>
    </div>

    <Modal v-if="state.showConnectModal" @close="close">
      <div>
        <div v-if="state.isLoggedIn" style="border-bottom: 1px solid #898989; text-align: center; padding-bottom: 20px; margin-bottom: 20px;">
          <h3>You are logged into Stacks Bridge!</h3>
          <button @click="close" class="button primary" style="margin-top: 10px;">Continue</button>
        </div>
        <div v-else style="text-align: center">
          <h3>Log into Stacks Bridge!</h3>
          <p>Login below to both your MetaMask wallet and Hiro wallet.</p>
        </div>
        <div class="connect-modal">
          <div class="connect-col">
            <div v-if="state.isCorrectNetwork">
              <div v-if="state.isMetaMaskInstalled">
                <div v-if="state.ethUser">
                  <p>Logged in as: {{ ethAddress }}</p>
                </div>
                <div v-else>
                  <button @click="handleConnect" class="button primary">Login with MetaMask</button>
                </div>
              </div>
              <div v-else>
                Please install metamask
              </div>
            </div>
            <div v-else>
              Wrong Network - Please use Ethereum Main Network
            </div>
          </div>

          <div class="connect-col">
            <div v-if="state.stacksUser">
              <p>Logged in as: {{ stxAddress }}</p>
              <button @click="stacksLogout" class="button">Log Out Stacks</button>
            </div>
            <div v-else>
              <button @click="stacksLogin" class="button primary">Login with Hiro Wallet</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from './Modal';
import { isDev, StacksBridge, state } from '@/main';
import { ref } from 'vue';
import { ethers } from 'ethers';
import { getShortStacksAddress } from '../helpers/getShortStacksAddress';
import {
  AppConfig,
  UserSession,
  showConnect,
} from "@stacks/connect";
import { isMetaMaskInstalled } from '../helpers/isMetaMaskInstalled';
import { updateBalance } from '../helpers/updateBalance';
import { getShortEthAddress } from '../helpers/getShortEthAddress';
import { updateAccount } from '../helpers/updateAccount';
import Icon from './Icon';

export default {
  components: {
    Modal,
    Icon
  },

  async setup() {
    const showLoginControls = ref(false);
    const stxAddress = ref(null);
    const ethAddress = ref(null);
    const appConfig = new AppConfig(["store_write", "publish_data"]);
    const userSession = new UserSession({appConfig});

    state.isMetaMaskInstalled = isMetaMaskInstalled();

    if (state.isMetaMaskInstalled) {
      state.isMetaMaskInstalled = true;

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      window.ethereum.on('accountsChanged', async () => {
        await updateAccount();
        ethAddress.value = getShortEthAddress(state.ethUser);
      });

      window.ethereum.on('chainChanged', async () => {
        window.location.reload();
      });

      provider.on('block', async () => {
        await updateBalance();
      });

      await updateAccount();
      ethAddress.value = getShortEthAddress(state.ethUser);
    }

    const updateStacksData = () => {
      state.userData = userSession.loadUserData() || null;
      state.stacksUser = isDev ? state.userData.profile.stxAddress.testnet : state.userData.profile.stxAddress.mainnet;
      stxAddress.value = getShortStacksAddress(state.stacksUser);
    };

    const handleConnect = async () => {
      try {
        await window.ethereum.request({method: 'eth_requestAccounts'});

        await updateAccount();
        ethAddress.value = getShortEthAddress(state.ethUser);
        showLoginControls.value = false;
      } catch (error) {
        console.error(error);
      }
    };

    if (userSession.isUserSignedIn()) {
      updateStacksData();
    }

    const stacksLogin = () => {
      if (userSession.isSignInPending()) {
        userSession.handlePendingSignIn().then(() => {
          updateStacksData();
        });

        return;
      }

      showConnect({
        appDetails: {
          name: "StacksBridge",
          icon: StacksBridge.icon,
        },
        redirectTo: "/",
        onCancel: () => {
          console.log('login cancelled');
        },
        onFinish: () => {
          updateStacksData();
          showLoginControls.value = false;
        },
        userSession: userSession,
      });
    };

    const stacksLogout = (e) => {
      e.stopPropagation();

      userSession.signUserOut();

      state.userData = null;
      state.stacksUser = null;
      stxAddress.value = null;
    };

    const open = () => {
      state.showConnectModal = true;
    };

    const close = () => {
      state.showConnectModal = false;
    };

    return {
      stacksLogin,
      stxAddress,
      ethAddress,
      stacksLogout,
      handleConnect,
      state,
      open,
      close,
      showLoginControls
    };
  }
};
</script>
