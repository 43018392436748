export function getShortEthAddress(address) {
    if (!address) {
        return null;
    }

    let start = address.substring(0, 6),
        end = address.slice(-4),
        result = (start + '...' + end).toUpperCase();

    return '0x' + result.substring(2);
}
