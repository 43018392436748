export default {
    "0x3738b2a29107942a2836601cF842E164c701238B": {
        "account": "0x3738b2a29107942a2836601cF842E164c701238B",
        "tickets": 10,
        "proof": [
            "0x8b8a580a1563cbefef2220bca60bb550bfbcf4cf35e4c5a66d2f326922a44253",
            "0x1a8c4b8554480538902b10b6c813099e87eb9dfa4f70450f7193e96b4e5ebc83",
            "0xed5a7ea1b0d7b7bd741717fb31bd36c875f6b6fdd13e8972eef75fb2135dbb1b"
        ]
    },
    "0xDadab55aEA5412904876A82a84Ba6f6284C5067B": {
        "account": "0xDadab55aEA5412904876A82a84Ba6f6284C5067B",
        "tickets": 10,
        "proof": [
            "0x4ff714be13b4668a679c75dfd35245c71266945826a5493411efe132c0b7c139",
            "0x6c30aabf3f903b4a1d10649a765c45909d84e73d8148c67e269600224014853f",
            "0xed5a7ea1b0d7b7bd741717fb31bd36c875f6b6fdd13e8972eef75fb2135dbb1b"
        ]
    },
    "0x2A310cF4f3257C2FA708a1b92570DF8EA46EB80a": {
        "account": "0x2A310cF4f3257C2FA708a1b92570DF8EA46EB80a",
        "tickets": 10,
        "proof": [
            "0x46427ae0576cd4afd5c3307147ccafc2dfcfb781d579cb3d295820029d78c61f",
            "0x6c30aabf3f903b4a1d10649a765c45909d84e73d8148c67e269600224014853f",
            "0xed5a7ea1b0d7b7bd741717fb31bd36c875f6b6fdd13e8972eef75fb2135dbb1b"
        ]
    },
    "0x82f5E198066d8f9991a24C0E59fF6b3EaB80e73e": {
        "account": "0x82f5E198066d8f9991a24C0E59fF6b3EaB80e73e",
        "tickets": 50,
        "proof": [
            "0xde9477becc7f0b63dd4c7e39c3761fad68af3699644f58beb9326e06402cdf92"
        ]
    },
    "0x84de297448a457dBf21719D7406A551E53c27239": {
        "account": "0x84de297448a457dBf21719D7406A551E53c27239",
        "tickets": 1,
        "proof": [
            "0xb88a922ae14bf3d0c0e9d2956828e09ce08e88236c4837d194939fe25473bf35",
            "0x1a8c4b8554480538902b10b6c813099e87eb9dfa4f70450f7193e96b4e5ebc83",
            "0xed5a7ea1b0d7b7bd741717fb31bd36c875f6b6fdd13e8972eef75fb2135dbb1b"
        ]
    }
};