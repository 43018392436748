export default [{
    "inputs": [{"internalType": "uint256", "name": "_initialBatchCount", "type": "uint256"}, {
        "internalType": "address",
        "name": "_immutableSatoshible",
        "type": "address"
    }, {"internalType": "uint256", "name": "_royaltiesPercentage", "type": "uint256"}], "stateMutability": "nonpayable", "type": "constructor"
}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {"indexed": true, "internalType": "address", "name": "approved", "type": "address"}, {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "Approval",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {"indexed": true, "internalType": "address", "name": "operator", "type": "address"}, {
        "indexed": false,
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
    }],
    "name": "ApprovalForAll",
    "type": "event"
}, {"anonymous": false, "inputs": [{"indexed": true, "internalType": "bool", "name": "hasElectricity", "type": "bool"}], "name": "LaboratoryStateChanged", "type": "event"}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "previousOwner", "type": "address"}, {"indexed": true, "internalType": "address", "name": "newOwner", "type": "address"}],
    "name": "OwnershipTransferred",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "creator", "type": "address"}, {"indexed": true, "internalType": "uint256", "name": "primeId", "type": "uint256"}, {
        "indexed": true,
        "internalType": "uint256",
        "name": "satId",
        "type": "uint256"
    }, {"indexed": false, "internalType": "uint256[4]", "name": "monsterIdsBurned", "type": "uint256[4]"}],
    "name": "PrimeCreated",
    "type": "event"
}, {"anonymous": false, "inputs": [], "name": "PublicSaleOpenedEarly", "type": "event"}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "bool", "name": "isActive", "type": "bool"}],
    "name": "SaleStateChanged",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "from", "type": "address"}, {"indexed": true, "internalType": "address", "name": "to", "type": "address"}, {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "Transfer",
    "type": "event"
}, {"inputs": [], "name": "MAX_PRESALE_SUPPLY", "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "MAX_PRESALE_TOKEN_ID",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "MAX_ROYALTIES_PCT", "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "MAX_SUPPLY",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "SATOSHIBLE_CONTRACT", "outputs": [{"internalType": "contract IHey", "name": "", "type": "address"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "TEAM_WALLET_A",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "TEAM_WALLET_B", "outputs": [{"internalType": "address", "name": "", "type": "address"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "TEAM_WALLET_C",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "TEAM_WALLET_D", "outputs": [{"internalType": "address", "name": "", "type": "address"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "WHITELIST_MERKLE_ROOT",
    "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "bool", "name": "_isActive", "type": "bool"}],
    "name": "activateSale",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "to", "type": "address"}, {"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
    "name": "approve",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "owner", "type": "address"}],
    "name": "balanceOf",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "baseURI", "outputs": [{"internalType": "string", "name": "", "type": "string"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "baseUriLocked",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "discountPrice", "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [{
        "internalType": "bool",
        "name": "_hasElectricity",
        "type": "bool"
    }], "name": "electrifyLaboratory", "outputs": [], "stateMutability": "nonpayable", "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
    "name": "getApproved",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {"internalType": "address", "name": "operator", "type": "address"}],
    "name": "isApprovedForAll",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "laboratoryHasElectricity", "outputs": [{"internalType": "bool", "name": "", "type": "bool"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "lockBaseURI",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {"inputs": [], "name": "lockProvenanceURI", "outputs": [], "stateMutability": "nonpayable", "type": "function"}, {
    "inputs": [],
    "name": "mintFinalPrime",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "_primeType", "type": "uint256"}, {"internalType": "uint256", "name": "_satId", "type": "uint256"}, {
        "internalType": "uint256[]",
        "name": "_monsterIds",
        "type": "uint256[]"
    }, {"internalType": "uint256[]", "name": "_monsterPrimeParts", "type": "uint256[]"}, {"internalType": "bytes32[][]", "name": "_proofs", "type": "bytes32[][]"}],
    "name": "mintPrimeToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "_numberOfTokens", "type": "uint256"}, {"internalType": "uint256[]", "name": "_satsForDiscount", "type": "uint256[]"}, {
        "internalType": "uint256",
        "name": "_whitelistedTokens",
        "type": "uint256"
    }, {"internalType": "bytes32[]", "name": "_proof", "type": "bytes32[]"}], "name": "mintTokensPresale", "outputs": [], "stateMutability": "payable", "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "_numberOfTokens", "type": "uint256"}, {"internalType": "uint256[]", "name": "_satsForDiscount", "type": "uint256[]"}],
    "name": "mintTokensPublicSale",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
}, {"inputs": [], "name": "monstersBurned", "outputs": [{"internalType": "uint256", "name": "burned", "type": "uint256"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "name",
    "outputs": [{"internalType": "string", "name": "", "type": "string"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "openPublicSaleEarly", "outputs": [], "stateMutability": "nonpayable", "type": "function"}, {
    "inputs": [],
    "name": "owner",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
    "name": "ownerOf",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "primePartsMerkleRoot",
    "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "_primeType", "type": "uint256"}],
    "name": "primeSupply",
    "outputs": [{"internalType": "uint256", "name": "supply", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "provenanceURI", "outputs": [{"internalType": "string", "name": "", "type": "string"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "provenanceUriLocked",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "publicSaleOpened", "outputs": [{"internalType": "bool", "name": "publicSaleIsOpen", "type": "bool"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "publicSaleOpenedEarly",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "renounceOwnership", "outputs": [], "stateMutability": "nonpayable", "type": "function"}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "_value", "type": "uint256"}],
    "name": "royaltyInfo",
    "outputs": [{"internalType": "address", "name": "receiver", "type": "address"}, {"internalType": "uint256", "name": "royaltyAmount", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }, {"internalType": "bytes", "name": "_data", "type": "bytes"}], "name": "safeTransferFrom", "outputs": [], "stateMutability": "nonpayable", "type": "function"
}, {"inputs": [], "name": "saleIsActive", "outputs": [{"internalType": "bool", "name": "", "type": "bool"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "_satId",
        "type": "uint256"
    }], "name": "satIsAvailableForDiscountMint", "outputs": [{"internalType": "bool", "name": "isAvailable", "type": "bool"}], "stateMutability": "view", "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "_satId", "type": "uint256"}],
    "name": "satIsAvailableForLab",
    "outputs": [{"internalType": "bool", "name": "isAvailable", "type": "bool"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256[]", "name": "_satIds", "type": "uint256[]"}],
    "name": "satsAvailableForDiscountMint",
    "outputs": [{"internalType": "uint256[]", "name": "", "type": "uint256[]"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256[]", "name": "_satIds", "type": "uint256[]"}],
    "name": "satsAvailableForLab",
    "outputs": [{"internalType": "uint256[]", "name": "", "type": "uint256[]"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "operator", "type": "address"}, {"internalType": "bool", "name": "approved", "type": "bool"}],
    "name": "setApprovalForAll",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "string", "name": "_newBaseURI", "type": "string"}],
    "name": "setBaseURI",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "bytes32", "name": "_merkleRoot", "type": "bytes32"}],
    "name": "setPrimePartsMerkleRoot",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "string", "name": "_newProvenanceURI", "type": "string"}],
    "name": "setProvenanceURI",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "_recipient", "type": "address"}, {"internalType": "uint256", "name": "_value", "type": "uint256"}],
    "name": "setRoyalties",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "bytes4", "name": "_interfaceId", "type": "bytes4"}],
    "name": "supportsInterface",
    "outputs": [{"internalType": "bool", "name": "doesSupportInterface", "type": "bool"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "symbol", "outputs": [{"internalType": "string", "name": "", "type": "string"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "tokenPrice",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
    "name": "tokenURI",
    "outputs": [{"internalType": "string", "name": "", "type": "string"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "totalPrimeSupply", "outputs": [{"internalType": "uint256", "name": "supply", "type": "uint256"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }], "name": "transferFrom", "outputs": [], "stateMutability": "nonpayable", "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "newOwner", "type": "address"}],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "_tokenPrice", "type": "uint256"}, {"internalType": "uint256", "name": "_discountPrice", "type": "uint256"}],
    "name": "updateTokenPrices",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "_monsterId", "type": "uint256"}, {"internalType": "uint256", "name": "_monsterPrimeParts", "type": "uint256"}, {
        "internalType": "bytes32[]",
        "name": "_proof",
        "type": "bytes32[]"
    }], "name": "verifyMonsterPrimeParts", "outputs": [{"internalType": "bool", "name": "isVerified", "type": "bool"}], "stateMutability": "view", "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "_account", "type": "address"}, {"internalType": "uint256", "name": "_whitelistedTokens", "type": "uint256"}, {
        "internalType": "bytes32[]",
        "name": "_proof",
        "type": "bytes32[]"
    }], "name": "verifyWhitelisted", "outputs": [{"internalType": "bool", "name": "isVerified", "type": "bool"}], "stateMutability": "pure", "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "", "type": "address"}],
    "name": "whitelistMintsUsed",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "_amount", "type": "uint256"}],
    "name": "withdraw",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "_token", "type": "address"}, {"internalType": "address", "name": "_to", "type": "address"}, {
        "internalType": "uint256",
        "name": "_amount",
        "type": "uint256"
    }, {"internalType": "bool", "name": "_hasVerifiedToken", "type": "bool"}], "name": "withdrawOther", "outputs": [], "stateMutability": "nonpayable", "type": "function"
}];
