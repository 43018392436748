import { StacksBridge, state } from '@/main';
import { getNetwork } from './getNetwork';

export const updateAccount = async () => {
    const accounts = await window.ethereum.request({method: 'eth_accounts'});

    const connected = accounts.length > 0;

    state.isMetaMaskConnected = connected;

    state.ethNetwork = await getNetwork();

    state.ethUser = connected ? accounts[0] : null;

    state.isCorrectNetwork = state.ethNetwork === StacksBridge.ethereumNetworkId;
};
