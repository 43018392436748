<template>
    <teleport to="body">
        <div id="modal" :class="['flex', className]" @click="handleClickOutside">
            <div class="container" :style="{maxWidth}">
                <div v-if="isLoading" class="loader">
                    <Spinner size="huge" />
                </div>
                <div v-else>
                    <div class="modal-header">
                        <h2 v-if="header">{{ header }}</h2>
                        <div v-if="!disableCloseButton" class="close-button flex" @click="close">
                            <Icon name="cross" />
                        </div>
                    </div>
                    <div class="content">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import Icon from './Icon';
import Spinner from './Spinner';

export default {
    components: {
        Icon,
        Spinner
    },
    props: {
        header: {
            type: String,
            default: null
        },
        className: {
            type: String,
            default: null
        },
        disableClosingOutside: {
            type: Boolean,
            default: false
        },
        disableCloseButton: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        maxWidth: {
            type: String,
            default: '600px'
        },
    },

    methods: {
        handleClickOutside(e) {
            if (this.disableClosingOutside || e.target.closest('.container')) {
                return;
            }

            this.close();
        },

        close() {
            this.$emit("close");
        }
    }
};
</script>
