<template>
    <h4>Ethereum ({{ nfts.length }})</h4>

    <div v-if="nfts.length" class="token-container">
        <label v-for="id of nfts" :class="['token', {selected: isSelected(id), disabled: isDisabled(id)}]" :key="id">
            <img :src="state.currentProject.getBaseUrl(id)" :alt="`${state.currentProject.name} #${id}`">
            <span class="name">{{ state.currentProject.name }} #{{ id }}</span>
            <input type="checkbox" :value="id" v-model="selected" :disabled="isDisabled(id)" />
        </label>
        <label v-if="nfts.length < 4" class="token gap"></label>
        <label v-if="nfts.length < 3" class="token gap"></label>
        <label v-if="nfts.length < 2" class="token gap"></label>
        <label v-if="nfts.length < 1" class="token gap"></label>
    </div>
    <div v-else class="panel-overlay">
        <div class="content">
            Your Ethereum wallet does not have any {{ state.currentProject.name }}!
        </div>
    </div>
    <button :disabled="selected.length < 1" @click="transfer" class="button panel-button primary">Transfer to Stacks ({{ selected.length }})</button>
    <div v-if="showEarlyAccessMessage" class="panel-overlay">
        <div class="content">
            <Icon name="cross" />
            <h3>You are not on the list</h3>
            <p>At the moment, only holders who are on the early access list can use the bridge. Join the Satoshibles discord if you would like some more info.</p>
            <a href="https://discord.gg/7Wm9Jg8MkW" target="_blank" class="button primary large">Join Discord</a>
        </div>
    </div>
    <Modal v-if="showTransferModal" @close="closeModal" :is-loading="isTransferModalLoading" class-name="transfer-modal" :disable-closing-outside="true">
        <div v-if="isApprovedForAll">
            <h3>Transfer to Stacks</h3>
            <p>You are about to bridge {{ selected.length }} {{ selected.length === 1 ? 'token' : 'tokens' }} to Stacks.</p>

            <div class="token-container">
                <label v-for="id of selected" :key="id" class="token-preview">
                    <img :src="state.currentProject.getBaseUrl(id)" :alt="`${state.currentProject.name} #${id}`">
                </label>
            </div>

            <div class="info-box">
                <p>Your NFTs will be sent to this Stacks address:</p>
                <input type="text" :value="state.stacksUser" style="width: 100%;" placeholder="Enter the Stacks address you want to send to..." disabled>
            </div>

            <div class="info-box blue">
                <p>
                    <strong>Note:</strong> When you decide to bridge back to Ethereum, you will need to pay an escrow fee on Stacks. The escrow fee is {{ state.escrowFee }} STX per NFT to bridge back. It will be used to cover the ETH gas fees for sending you back your NFTs.
                </p>
            </div>

            <div v-if="isTransferring" style="margin-top: 30px;">
                <p style="text-align: center; margin: 10px 0 15px;">
                    <Spinner size="small" />
                </p>
                <p v-if="ethTxId" class="step-info">NFT(s) are being locked to the NFT Bridge:
                    <a v-if="ethTxId" target="_blank" :href="`https://${network === 'testnet' ? 'goerli.' : ''}etherscan.io/tx/${ethTxId}`">View Transaction on Etherscan</a></p>
                <p v-if="stacksTxId" class="step-info">NFT(s) are being released by the NFT Bridge, this may take some time while waiting on anchor block.
                    <a v-if="stacksTxId" target="_blank" :href="`https://explorer.stacks.co/txid/${stacksTxId}?chain=${network}`">View Transaction on Stacks Explorer</a></p>
            </div>

            <div v-if="!isTransferring">
                <label class="terms">
                    <input type="checkbox" v-model="termsAccepted">
                    <span>To continue you must click here to agree to <span class="link-style" @click.prevent="state.showTerms = true">the terms</span>.</span>
                </label>
                <div class="button-wrap">
                    <button type="button" @click="closeModal" :disabled="isTransferring" class="button">Cancel</button>
                    <button type="button" @click="submit" :disabled="isTransferring || !termsAccepted" class="button primary">Initiate Transfer</button>
                </div>
            </div>
        </div>
        <div v-else style="text-align: center; padding-bottom: 1px">
            <h4>Before we begin...</h4>
            <p style="margin-top: 30px;">You will need to approve StacksBridge for Monster Satoshibles transfers:</p>
            <button @click="approve" class="button primary" style="margin-top: 20px;">Approve Now</button>
        </div>
        <div v-if="modalError" class="notification">
            <p>Oops, something went wrong, please try again.</p>
            <p>{{ modalError }}</p>
        </div>
    </Modal>
</template>

<script>
import SATS_ABI from '../abi/monsters.js';
import BRIDGE_ABI from '../abi/stacks-bridge.js';
import EARLY_ACCESS_PROOFS from '../../early-access-proofs.js';
import EARLY_ACCESS_PROOFS_PROD from '../../early-access-proofs-prod.js';
import { fetchOwnedMonsters } from '../helpers/fetchOwnedMonsters';
import { zeroPad } from '../helpers/zeroPad';
import { computed, ref, watch } from 'vue';
import Modal from './Modal';
import { ethers } from 'ethers';
import { isDev, StacksBridge, state } from '@/main';
import Icon from '@/js/components/Icon';
import axios from 'axios';
import { unHex } from '../helpers/unHex';
import Spinner from '@/js/components/Spinner';

export default {
    components: {
        Spinner,
        Modal,
        Icon,
    },

    async setup() {
        const refreshRate = 10000;
        const MAX_BATCH_COUNT = 50;
        const nfts = ref([]);
        const ticketsLeft = ref(0);
        const isApprovedForAll = ref(false);
        const termsAccepted = ref(false);
        const modalError = ref('');
        const showTransferModal = ref(false);
        const isTransferModalLoading = ref(false);
        const escrowFee = ref('');
        const escrowFeeTotal = ref('');
        const userEarlyAccessInfo = ref(null);
        const userEarlyAccessUsed = ref(null);
        const bridgeIsOpen = ref(null);
        const bridgeIsOpenToPublic = ref(null);
        const selected = ref([]);
        const stacksTxId = ref('');
        const ethTxId = ref('');
        const isTransferring = ref(false);

        watch(() => state.ethUser, async (ethUser) => {
            if (!ethUser) {
                return;
            }

            await refresh();
        });

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const SatsContract = new ethers.Contract(StacksBridge.contracts.monsters.nft, SATS_ABI, provider);
        const SatsSigner = SatsContract.connect(signer);
        const BridgeContract = new ethers.Contract(StacksBridge.contracts.monsters.bridge, BRIDGE_ABI, provider);
        const BridgeSigner = BridgeContract.connect(signer);
        const gasEscrowFee = await BridgeContract.gasEscrowFee();
        const baseUrl = StacksBridge.baseUrl;

        const proofs = isDev ? EARLY_ACCESS_PROOFS : EARLY_ACCESS_PROOFS_PROD;

        const refresh = async () => {
            nfts.value = await fetchOwnedMonsters();
            escrowFee.value = ethers.utils.formatEther(gasEscrowFee.toString());
            bridgeIsOpenToPublic.value = await BridgeContract.bridgeIsOpenToPublic();
            bridgeIsOpen.value = await BridgeContract.bridgeIsOpen();
            userEarlyAccessInfo.value = proofs[ethers.utils.getAddress(state.ethUser)];
            userEarlyAccessUsed.value = (await BridgeContract.earlyAccessTicketsUsed(state.ethUser)).toNumber();
            ticketsLeft.value = userEarlyAccessInfo.value ? userEarlyAccessInfo.value.tickets - userEarlyAccessUsed.value : 0;
        };

        const isMaxSelected = computed(() => {
            return selected.value.length >= (bridgeIsOpenToPublic.value ? MAX_BATCH_COUNT : ticketsLeft.value > MAX_BATCH_COUNT ? MAX_BATCH_COUNT : ticketsLeft.value);
        });

        let fetchInterval;

        const fetchRegularly = async () => {
            clearInterval(fetchInterval);

            fetchInterval = setInterval(async function () {
                try {
                    nfts.value = await fetchOwnedMonsters();
                    // console.log('fetchRegularly ', nfts.value)
                } catch (error) {
                    clearInterval(fetchInterval);
                }
            }, refreshRate);
        };

        const isSelected = (id) => {
            return selected.value.includes(id);
        };

        const isDisabled = (id) => {
            if (state.hasMonstersPending) {
                return true;
            }

            return isMaxSelected.value && !isSelected(id);
        };

        const transfer = async () => {
            isTransferModalLoading.value = true;
            showTransferModal.value = true;

            isApprovedForAll.value = await SatsContract.isApprovedForAll(state.ethUser, StacksBridge.contracts.monsters.bridge);

            isTransferModalLoading.value = false;

            escrowFeeTotal.value = ethers.utils.formatEther(gasEscrowFee.mul(selected.value.length).toString());
        };

        const approve = async (e) => {
            e.stopPropagation();

            isTransferModalLoading.value = true;

            const tx = await SatsSigner.setApprovalForAll(
                StacksBridge.contracts.monsters.bridge,
                true
            ).catch(err => {
                console.log(err);
                isTransferModalLoading.value = false;
                modalError.value = err.message;
                isTransferring.value = false;
            });

            if (tx) {
                const receipt = await tx.wait();

                if (receipt.status === 1) {
                    isApprovedForAll.value = true;
                    isTransferModalLoading.value = false;
                }
            }
        };

        const closeModal = () => {
            showTransferModal.value = false;
            modalError.value = null;
            isTransferring.value = false;
            termsAccepted.value = false;
            selected.value = [];
        };

        const submit = async (e) => {
            isTransferring.value = true;
            e.stopPropagation();
            const toEscrow = gasEscrowFee.mul(selected.value.length).toString();

            let tx;

            if (bridgeIsOpen.value) {
                if (!bridgeIsOpenToPublic.value) {
                    if (!userEarlyAccessInfo.value) {
                        console.log("No early access proofs found for this address");
                        return;
                    }

                    tx = await BridgeSigner.lockEarlyAccess(
                        selected.value,
                        state.stacksUser,
                        userEarlyAccessInfo.value.tickets,
                        userEarlyAccessInfo.value.proof,
                        { value: toEscrow }
                    ).catch(err => {
                        modalError.value = err.message;
                    });

                } else {
                    tx = await BridgeSigner.lock(
                        selected.value,
                        state.stacksUser,
                        { value: toEscrow }
                    ).catch(err => {
                        modalError.value = err.message;
                    });

                }
            }

            if (tx) {
                console.log('tx sent ', tx);
                // @TODO Now what do we do? Listen for transactions on stacks wallet?
                ethTxId.value = tx.hash;
                await findStacksTxId();

                const receipt = await tx.wait();
                console.log(receipt);
            }
        };

        let stacksInterval;

        const findStacksTxId = async () => {
            clearInterval(stacksInterval);

            stacksInterval = setInterval(async function () {
                try {
                    const response = await axios.get(`${StacksBridge.api.monsters}/getStacksTxId?ethTxId=${unHex(ethTxId.value)}`);
                    console.log('findStacksTxId response.data', response.data);
                    if (response.data !== '') {
                        stacksTxId.value = response.data;
                        clearInterval(stacksInterval);
                    }
                    //  else {
                    //   state.pendingStacksTxId = stacksTxId.value;
                    // }
                } catch (error) {
                    console.log('findStacksTxId error ', error.message);
                    clearInterval(stacksInterval);
                }
            }, 1000);
        };

        await refresh();
        await fetchRegularly();

        const showEarlyAccessMessage = false;

        const network = isDev ? 'testnet' : 'mainnet';

        const ticketsTotal = userEarlyAccessInfo.value ? userEarlyAccessInfo.value.tickets - userEarlyAccessUsed.value : 0;

        watch(() => state.currentProject, () => {
            clearInterval(stacksInterval);
            clearInterval(fetchInterval);
        });

        return {
            ticketsTotal,
            bridgeIsOpenToPublic,
            ticketsLeft,
            state,
            network,
            selected,
            showTransferModal,
            isApprovedForAll,
            nfts,
            isMaxSelected,
            showEarlyAccessMessage,
            isTransferModalLoading,
            modalError,
            escrowFee,
            escrowFeeTotal,
            baseUrl,
            transfer,
            approve,
            submit,
            termsAccepted,
            zeroPad,
            isDisabled,
            isSelected,
            closeModal,
            isTransferring,
            stacksTxId,
            ethTxId,
        };
    }
};
</script>
