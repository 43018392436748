import { createApp, reactive, computed } from 'vue';
import Bridge from '@/js/components/Bridge';
import { Buffer } from "@stacks/common";
import { zeroPad } from '@/js/helpers/zeroPad';

global.Buffer = Buffer;

fetch('/icons.svg').then(response => response.text()).then(data => {
    document.body.insertAdjacentHTML('afterbegin', data);
});

const isLoggedIn = computed(() => {
    return state.stacksUser !== null
        && state.ethUser !== null
        && state.isCorrectNetwork;
});

export const isDev = process.env.NODE_ENV === "development";

export const StacksBridge = {
    icon: "https://stacksbridge.com/favicon.png",
    baseUrl: "/",
    ethereumNetworkId: isDev ? 5 : 1,
    stacksNetworkId: isDev ? "mainnet" : "testnet",
    stacksNode: isDev ? 'https://solemn-evocative-diagram.stacks-testnet.quiknode.pro/763bf5686ed76079da40152d39ea86b0032da41b' : 'https://evocative-palpable-wildflower.stacks-mainnet.quiknode.pro/c52afa503c2fbb2a1937f910203d6fc0aafcde68',
    contracts: {
        satoshibles: {
            nft: isDev ? "0x7dD3869DBD8120524Fc6ec69afE325f610c04449" : "0x0B0b186841C55D8a09d53Db48dc8cab9dbf4Dbd6",
            bridge: isDev ? "0x8CF0871ED06FD87FE84e219297E1251FF4501545" : "0x581A804895ccFeE045f658A4fB7Ecb2FbD5F6724",
        },
        monsters: {
            nft: isDev ? "0xd2402e8828147D4a8D5d983c69657cB315D0ffbB" : "0xeCfcf35750129322C6D746b37d6399FE9cF17e8b",
            bridge: isDev ? "0xe81E1F272971Ab89a2167584a30215420B2BA09f" : "0x5db2aab6c88931d59de519220FFD2e394E1C755f",
        },
    },
    api: {
        satoshibles: isDev ? "https://stacksbridge-api.herokuapp.com" : "https://stacksbridge-api-prod.herokuapp.com",
        monsters: isDev ? "https://monster-bridge-api-dev.herokuapp.com" : "https://monster-bridge-api-prod.herokuapp.com"
    }
};

export const Projects = {
    satoshibles: {
        name: 'Satoshibles',
        subgraphUrl: `https://api.thegraph.com/subgraphs/name/coffee-converter/satoshibles${StacksBridge.ethereumNetworkId === 5 ? "-goerli" : ""}`,
        getBaseUrl: (id, showAlt) => {
            if (showAlt) {
                return `./token/satoshibles/btc/${zeroPad(id, 4)}.png`;
            }

            return `./token/satoshibles/original/${zeroPad(id, 4)}.png`;
        }
    },
    monsterSatoshibles: {
        name: 'Monster Satoshibles',
        subgraphUrl: `https://api.thegraph.com/subgraphs/name/coffee-converter/monster-satoshibles${StacksBridge.ethereumNetworkId === 5 ? "-goerli" : ""}`,
        getBaseUrl: (id) => {
            if (id > 3305 && id < 666001) {
                return `./token/monsters/monsters-temp.gif`;
            }

            if (id > 6666) {
                return `./token/monsters/${zeroPad(id, 4)}.jpg`;
            }

            return `./token/monsters/${zeroPad(id, 4)}.png`;
        }
    }
};

export const state = reactive({
    hasSatsPending: false,
    hasMonstersPending: false,
    maintenanceMode: false,
    currentProject: Projects.satoshibles,
    stacksUser: null,
    ethUser: null,
    ethNetwork: 0,
    ethBalance: 0,
    showTerms: false,
    escrowFee: 0,
    showConnectModal: false,
    isMetaMaskInstalled: false,
    isCorrectNetwork: false,
    isMetaMaskConnected: false,
    userData: null,
    isLoggedIn
});

createApp(Bridge).mount('#app');
