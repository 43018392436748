<template>
    <div v-if="isAdmin" class="admin-panel">
        <form @submit.prevent="withdrawStacks">
            <span>Current Balance: {{ contractBalance }} STX</span>
            <input type="text" required v-model="withdrawAddress">
            <input type="number" required :max="contractBalance" min="1" v-model="withdrawAmount">
            <button class="button primary">Withdraw Stacks</button>
        </form>
    </div>

    <h4 v-if="pendingNfts.length" class="pending-title">Pending ({{ pendingNfts.length }})
        <Spinner size="tiny" />
    </h4>
    <p v-if="pendingNfts.length" class="pending-tx"><a v-for="tx of pendingTx" key="tx" :href="`https://explorer.stacks.co/txid/${tx}?chain=${network}`" target="_blank">View Transaction</a></p>
    <div v-if="pendingNfts.length" class="token-container">
        <label v-for="id of pendingNfts" :class="['token', {selected: false, disabled: true}]" :key="id">
            <img :src="state.currentProject.getBaseUrl(id, true)" :alt="`${state.currentProject.name} #${id}`">
            <span class="name">{{ state.currentProject.name }} #{{ id }}</span>
        </label>
        <label v-if="pendingNfts.length < 4" class="token gap"></label>
        <label v-if="pendingNfts.length < 3" class="token gap"></label>
        <label v-if="pendingNfts.length < 2" class="token gap"></label>
        <label v-if="pendingNfts.length < 1" class="token gap"></label>
    </div>

    <h4>Stacks ({{ nfts.length }})</h4>

    <div v-if="nfts.length" class="token-container">
        <label v-for="id of nfts" :class="['token', {selected: isSelected(id), disabled: isDisabled(id)}]" :key="id">
            <span v-if="id > 3305 && id < 666000" class="not-bridgeable">Not Bridgeable</span>
            <img :src="state.currentProject.getBaseUrl(id, true)" :alt="`${state.currentProject.name} #${id}`">
            <span class="name">{{ state.currentProject.name }} #{{ id }}</span>
            <input type="checkbox" :value="id" v-model="selected" :disabled="isDisabled(id)" />
        </label>
        <label v-if="nfts.length < 4" class="token gap"></label>
        <label v-if="nfts.length < 3" class="token gap"></label>
        <label v-if="nfts.length < 2" class="token gap"></label>
        <label v-if="nfts.length < 1" class="token gap"></label>
    </div>

    <div v-if="nfts.length < 1 && pendingNfts.length < 1" class="panel-overlay">
        <div class="content">
            Your Stacks wallet does not have any {{ state.currentProject.name }}!
        </div>
    </div>

    <button :disabled="selected.length < 1" @click="transfer" class="button panel-button primary">Transfer to Ethereum ({{ selected.length }})</button>

    <Modal v-if="showTransferModal" @close="closeModal" :is-loading="isTransferModalLoading" class-name="transfer-modal" :disable-closing-outside="true">
        <h3>Transfer to Ethereum</h3>
        <div v-if="listed.length">
            <p class="notification">You cannot transfer back any NFTs that are currently listed on the marketplace. You will need to de-list first.</p>

            <p>Your currently listed NFTs:</p>
            <ul>
                <li v-for="tokenId of listed" :key="tokenId"><strong>{{ state.currentProject.name }} #{{ tokenId }}</strong></li>
            </ul>

            <p>Once you have unlisted these NFTs from the marketplace, you can come back and try again.</p>

            <div class="button-wrap">
                <button class="primary button" style="margin-left: auto" @click="closeModal">Ok</button>
            </div>
        </div>
        <div v-else>
            <p>You are about to bridge {{ selected.length }} {{ selected.length === 1 ? 'token' : 'tokens' }} to Ethereum.</p>

            <div class="token-container">
                <label v-for="id of selected" :key="id" class="token-preview">
                    <img :src="state.currentProject.getBaseUrl(id, true)" :alt="`${state.currentProject.name} #${id}`">
                </label>
            </div>

            <p>Your NFTs will be sent to this Ethereum address:</p>
            <input type="text" :value="state.ethUser" style="width: 100%;" placeholder="Enter the Ethereum address you want to send to..." disabled>

            <p>Escrow fee per NFT: <strong>{{ state.escrowFee }} STX</strong></p>
            <p>Total Cost: <strong>{{ escrowFeeTotal }} STX</strong></p>

            <div v-if="isTransferring" style="margin-top: 30px;">
                <p style="text-align: center; margin: 10px 0 15px;">
                    <Spinner size="small" />
                </p>
                <p v-if="stacksTxId" class="step-info">NFT(s) are being locked to the NFT Bridge, this may take some time while waiting on anchor block.
                    <a target="_blank" :href="`https://explorer.stacks.co/txid/${stacksTxId}?chain=${network}`">View Transaction on Stacks Explorer</a></p>
                <p v-if="ethTxId" class="step-info">NFT(s) are being released by the NFT Bridge:
                    <a target="_blank" :href="`https://${network === 'testnet' ? 'goerli.' : ''}etherscan.io/tx/${ethTxId}`">View Transaction on Etherscan</a></p>
            </div>

            <div v-if="!isTransferring">
                <label class="terms">
                    <input type="checkbox" v-model="termsAccepted">
                    <span>To continue you must click here to agree to <span class="link-style" @click.prevent="state.showTerms = true">the terms</span>.</span>
                </label>
                <div class="button-wrap">
                    <button @click="closeModal" :disabled="isTransferring" class="button">Cancel</button>
                    <button @click="submit" :disabled="isTransferring || !termsAccepted" class="button primary">Initiate Transfer</button>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import { isDev, StacksBridge, state } from '@/main';
import { computed, ref, watch } from 'vue';
import { openContractCall } from "@stacks/connect";
import { StacksMainnet, StacksTestnet } from "@stacks/network";
import {
    uintCV,
    stringAsciiCV,
    tupleCV,
    listCV,
    PostConditionMode,
    NonFungibleConditionCode,
    createAssetInfo,
    FungibleConditionCode,
    makeStandardNonFungiblePostCondition,
    makeStandardSTXPostCondition,
    callReadOnlyFunction,
    cvToJSON
} from "@stacks/transactions";
import { zeroPad } from '@/js/helpers/zeroPad';
import Modal from '@/js/components/Modal';
import axios from 'axios';
import { unHex } from '../helpers/unHex';
import Spinner from '@/js/components/Spinner';
import { firstBy } from 'thenby';
import { principalCV } from '@stacks/transactions/dist/clarity/types/principalCV';

export default {
    components: {
        Modal,
        Spinner
    },

    async setup() {
        const refreshRate = 10000;
        const nfts = ref([]);
        const listed = ref([]);
        const selected = ref([]);
        const termsAccepted = ref(false);
        const showTransferModal = ref(false);
        const isTransferModalLoading = ref(false);
        const isTransferring = ref(false);
        const modalError = ref('');
        const MAX_BATCH_COUNT = 50;
        const baseUrl = StacksBridge.baseUrl;
        const escrowFeeTotal = ref(0);
        const contractBalance = ref(0);
        const withdrawAmount = ref(0);
        const withdrawAddress = ref('');
        const stacksTxId = ref('');
        const ethTxId = ref('');
        const pendingNfts = ref([]);
        const pendingTx = ref([]);
        const pendingInterval = ref(null);

        const node = {
            url: StacksBridge.stacksNode
        };

        const activeNetwork = isDev ? new StacksTestnet(node) : new StacksMainnet(node);

        const contractAddress = isDev ? "ST11T7WK8T6E80AK8G67G46JVNM4MNMYGDD20Y4QW.monster-satoshibles" : "SP6P4EJF0VG8V0RB3TQQKJBHDQKEF6NVRD1KZE3C.monster-satoshibles";
        const bridgeWorkerAddress = isDev ? "ST11T7WK8T6E80AK8G67G46JVNM4MNMYGDD20Y4QW" : "SP2RBS3R0C0JCDBM1BAV1HJ57DD1V3BSRGTAJMWWN";
        const bridgeContractAddress = isDev ? "ST11T7WK8T6E80AK8G67G46JVNM4MNMYGDD20Y4QW.stacksbridge-monster-satoshibles" : "SP6P4EJF0VG8V0RB3TQQKJBHDQKEF6NVRD1KZE3C.stacksbridge-monster-satoshibles";

        const isAdmin = computed(() => {
            return state.stacksUser === bridgeContractAddress.split(".")[0];
        });

        if (isAdmin.value) {
            try {
                const response = await axios.get(`${StacksBridge.stacksNode}/extended/v1/address/${bridgeContractAddress}/stx`);

                contractBalance.value = Number(response.data.balance) / 1000000;
                withdrawAmount.value = contractBalance.value;
                withdrawAddress.value = state.stacksUser;
            } catch (error) {
                console.log('Get Contract Balance Error ', error.message);
            }
        }

        const withdrawStacks = async () => {
            const postConditions = [];
            const postConditionMode = PostConditionMode.Allow;

            const functionArgs = [
                principalCV(withdrawAddress.value),
                uintCV(withdrawAmount.value * 1000000),
            ];

            const contractAddress = bridgeContractAddress.split(".")[0];
            const contractName = bridgeContractAddress.split(".")[1];

            const options = {
                network,
                contractAddress,
                contractName,
                functionName: 'transfer-stx',
                functionArgs,
                postConditionMode,
                postConditions,
                appDetails: {
                    name: "Stacks Bridge",
                    icon: StacksBridge.icon,
                },
                onCancel: () => {
                    console.log('Cancelled...');
                },
                onFinish: (data) => {
                    console.log("Stacks Transaction:", data.stacksTransaction);
                    console.log("Transaction ID:", data.txId);
                },
            };

            await openContractCall(options);
        };

        const isListed = async (tokenID) => {
            const functionArgs = [
                uintCV(tokenID),
            ];

            const options = {
                contractAddress: contractAddress.split(".")[0],
                contractName: contractAddress.split(".")[1],
                functionName: "get-listing-in-ustx",
                network: activeNetwork,
                functionArgs,
                senderAddress: state.stacksUser,
            };

            const res = await callReadOnlyFunction(options);

            return cvToJSON(res).value;
        };

        const getEscrowFee = async () => {
            const functionArgs = [];

            const options = {
                contractAddress: bridgeContractAddress.split(".")[0],
                contractName: bridgeContractAddress.split(".")[1],
                functionName: "get-escrow-fee",
                network: activeNetwork,
                functionArgs,
                senderAddress: state.stacksUser,
            };

            const res = await callReadOnlyFunction(options);

            return Number(cvToJSON(res).value.value) / 1000000;
        };

        state.escrowFee = await getEscrowFee();

        const refreshListed = async () => {
            const listedNfts = [];

            for (let id of selected.value) {
                console.log('Checking is listed:', id);

                if (await isListed(id) !== null) {
                    listedNfts.push(id);
                }
            }

            listed.value = listedNfts;

            console.log('listed nfts:', listed.value);
        };

        const refresh = async () => {
            try {
                const url = `${StacksBridge.stacksNode}/extended/v1/tokens/nft/holdings?principal=${state.stacksUser}&limit=200`;
                const response = await axios.get(url);

                let updatedNfts = [];
                let results;
                let totalAmount = response.data.total;
                let offset = 200;

                if (totalAmount <= 200) {
                    results = response.data.results;
                } else {
                    results = [...response.data.results];

                    const times = Math.ceil(totalAmount / 200);

                    for (let i = 0; i < times; i++) {
                        if (offset < totalAmount) {
                            let response = await axios.get(url + `&offset=${offset}`);

                            offset += response.data.results.length;

                            if (offset > 0) {
                                results = [...results, ...response.data.results];
                            }
                        }
                    }
                }

                results.filter((item) => {
                    let id = Number(item.value.repr.replace('u', ''));

                    if (item.asset_identifier === contractAddress + '::MonsterSatoshibles' && !updatedNfts.includes(id) && !pendingNfts.value.includes(id)) {
                        updatedNfts.push(id);
                    }
                });

                nfts.value = updatedNfts.sort(firstBy((v) => v > 3000 && v < 666001).thenBy((a, b) => b - a));
                // console.log('stacks nfts ', updatedNfts);
            } catch (error) {
                console.log('get nft holdings error ', error.message);
            }
        };

        let refreshInterval;

        const initRefresh = async () => {
            clearInterval(refreshInterval);

            await refresh();

            try {
                refreshInterval = setInterval(async function () {
                    await refresh();
                }, refreshRate);
            } catch (error) {
                console.log('refreshRegularly error ', error.message);
                clearInterval(refreshInterval);
            }
        };

        const isMaxSelected = computed(() => {
            return selected.value.length >= MAX_BATCH_COUNT;
        });

        const isSelected = (id) => {
            return selected.value.includes(id);
        };

        const isDisabled = (id) => {
            if (state.hasMonstersPending || id > 3305 && id < 666001) {
                return true;
            }

            return isMaxSelected.value && !isSelected(id);
        };

        const closeModal = () => {
            showTransferModal.value = false;
            modalError.value = null;
            isTransferring.value = false;
            termsAccepted.value = false;
            selected.value = [];
        };

        const transfer = async () => {
            isTransferModalLoading.value = true;
            showTransferModal.value = true;

            await refreshListed();

            isTransferModalLoading.value = false;
            escrowFeeTotal.value = state.escrowFee * selected.value.length;
        };

        const submit = async (e) => {
            e.stopPropagation();
            isTransferring.value = true;

            try {
                let tupleArray = [];

                for (let sat of selected.value) {
                    const tupCV = tupleCV({
                        id: uintCV(sat),
                        address: stringAsciiCV(state.ethUser)
                    });

                    tupleArray.push(tupCV);
                }

                const functionArgs = [listCV(tupleArray)];

                const postConditionMode = PostConditionMode.Deny;
                const postConditionAddress = state.stacksUser;
                const postConditionCode = NonFungibleConditionCode.DoesNotOwn;
                const assetAddress = contractAddress.split('.')[0];
                const assetContractName = contractAddress.split('.')[1];
                const assetName = isDev ? 'MonsterSatoshibles' : 'MonsterSatoshibles';
                const nonFungibleAssetInfo = createAssetInfo(assetAddress, assetContractName, assetName);

                let postConditions = [];

                const standardFungiblePostCondition = makeStandardSTXPostCondition(
                    state.stacksUser,
                    FungibleConditionCode.LessEqual,
                    escrowFeeTotal.value * 1000000
                );

                postConditions.push(standardFungiblePostCondition);

                for (let sat of selected.value) {
                    const tokenAssetName = uintCV(sat);

                    const standardNonFungiblePostCondition = makeStandardNonFungiblePostCondition(
                        postConditionAddress,
                        postConditionCode,
                        nonFungibleAssetInfo,
                        tokenAssetName
                    );

                    postConditions.push(standardNonFungiblePostCondition);
                }

                const options = {
                    network: activeNetwork,
                    contractAddress: bridgeContractAddress.split(".")[0],
                    contractName: bridgeContractAddress.split(".")[1],
                    functionName: "lock-many",
                    functionArgs,
                    postConditionMode,
                    postConditions,
                    appDetails: {
                        name: "Stacks Bridge",
                        icon: StacksBridge.icon,
                    },
                    onCancel: () => {
                        isTransferring.value = false;
                    },
                    onFinish: (data) => {
                        console.log("Stacks Transaction:", data.stacksTransaction);
                        console.log("Transaction ID:", data.txId);
                        // console.log("Raw transaction:", data.txRaw);
                        // const explorerTransactionUrl = "https://explorer.stacks.co/txid/" + data.txId + "?chain=" + activeNetworkStr;
                        // console.log("View transaction in explorer:", explorerTransactionUrl);

                        // stacks tx sent
                        stacksTxId.value = data.txId;
                        findEthTxId();

                        // mempool tracking is implemented
                        // manually populate pendingNFTs because they are sent from Stacks in mempool but not on eth yet.
                        // clearInterval(pendingInterval.value);
                        // for (let sat of selected.value) {
                        //   const satobj = {lockedids: sat}
                        //   pendingNfts.value.push(satobj);
                        // }
                    },
                };

                await openContractCall(options);

            } catch (error) {
                console.log('error: ', error);
            }
        };

        let txInterval;

        const findEthTxId = async () => {
            clearInterval(txInterval);

            txInterval = setInterval(async function () {
                try {
                    const response = await axios.get(`${StacksBridge.api.monsters}/getEthTxId?stacksTxId=${unHex(stacksTxId.value)}`);
                    ethTxId.value = response.data;
                    // console.log('findEthTxId ', ethTxId.value)
                    if (ethTxId.value !== '') {
                        clearInterval(txInterval);
                    }
                } catch (error) {
                    console.log('findEthTxId error ', error.message);
                    clearInterval(txInterval);
                }

            }, refreshRate);
        };

        const refreshPending = async () => {
            try {
                if (state.stacksUser === null || state.stacksUser === '') clearInterval(pendingInterval.value);

                // alternative method for eth -> stacks
                // const ingressmempooltxns = await transactionsApi.getMempoolTransactionList({ senderAddress: bridgeWorkerAddress });
                //console.log('ingressmempooltxns ', ingressmempooltxns);
                const response = await axios.get(`${StacksBridge.stacksNode}/extended/v1/tx/mempool?sender_address=${bridgeWorkerAddress}&cache=${new Date().getTime()}`);
                const ingressmempooltxns = response.data;

                var mempoollist = [];
                pendingTx.value = [];

                for (let index = 0; index < ingressmempooltxns.results.length; index++) {
                    const mempooltx = ingressmempooltxns.results[index];

                    if (mempooltx.tx_type == "contract_call" &&
                        mempooltx.contract_call &&
                        mempooltx.contract_call.contract_id == bridgeContractAddress &&
                        mempooltx.contract_call.function_name == "release-many" &&
                        mempooltx.contract_call.function_args[0].repr.includes(state.stacksUser)) {

                        const str = mempooltx.contract_call.function_args[0].repr;
                        const ids = Array.from(str.matchAll(/u\d+/g), (m) => Number(m[0].replace('u', '')));

                        mempoollist.push(...ids);

                        pendingTx.value.push(mempooltx.tx_id);
                    }
                }

                // inconsistent results - TODO later
                // check if user sent anything from stacks
                // const egressmempooltxns = await transactionsApi.getMempoolTransactionList({ senderAddress: state.stacksUser });
                // console.log('egressmempooltxns ', egressmempooltxns);
                const egressResponse = await axios.get(`${StacksBridge.stacksNode}/extended/v1/tx/mempool?sender_address=${state.stacksUser}&cache=${new Date().getTime()}`);
                const egressmempooltxns = egressResponse.data;

                var egressmempoollist = [];

                for (let index = 0; index < egressmempooltxns.results.length; index++) {
                    const egressmempooltx = egressmempooltxns.results[index];

                    if (egressmempooltx.tx_type == "contract_call" &&
                        egressmempooltx.contract_call &&
                        egressmempooltx.contract_call.contract_id == bridgeContractAddress &&
                        egressmempooltx.contract_call.function_name.includes("lock-many") &&
                        egressmempooltx.post_conditions) {

                        const str = egressmempooltx.contract_call.function_args[0].repr;
                        const ids = Array.from(str.matchAll(/u\d+/g), (m) => Number(m[0].replace('u', '')));

                        egressmempoollist.push(...ids);
                        pendingTx.value.push(egressmempooltx.tx_id);
                    }
                }

                pendingNfts.value = [...mempoollist, ...egressmempoollist];

                state.hasMonstersPending = pendingNfts.value.length > 0;
            } catch (error) {
                console.log('refreshPending error ', error.message);
                clearInterval(pendingInterval.value);
            }
        };

        const initPending = async () => {
            await refreshPending();

            clearInterval(pendingInterval.value);

            if (state.stacksUser) {
                pendingInterval.value = setInterval(async function () {
                    await refreshPending();
                }, refreshRate);
            }
        };

        await initPending();
        await initRefresh();

        const network = isDev ? 'testnet' : 'mainnet';

        watch(() => state.currentProject, () => {
            clearInterval(pendingInterval.value);
            clearInterval(txInterval);
            clearInterval(refreshInterval);
        });

        return {
            nfts,
            baseUrl,
            selected,
            network,
            state,
            isSelected,
            escrowFeeTotal,
            zeroPad,
            transfer,
            showTransferModal,
            closeModal,
            submit,
            termsAccepted,
            isTransferModalLoading,
            isDisabled,
            pendingTx,
            listed,
            isTransferring,
            stacksTxId,
            withdrawAddress,
            isAdmin,
            withdrawAmount,
            withdrawStacks,
            contractBalance,
            StacksBridge,
            ethTxId,
            pendingNfts,
        };
    }
};
</script>
