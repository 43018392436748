<template>
    <div v-if="state.maintenanceMode" id="maintenance-mode">
        <div class="maintenance-mode-content">
            <p><img class="logo" src="../../images/logo.svg" alt="StacksBridge"></p>
            <h3>We are updating a few things!</h3>
            <p>We are currently making some updates to StacksBridge. Please come back and try again later. If you have any questions, you can join the discord below and someone will be able to help.</p>
            <p style="margin-top: 40px">Estimated time remaining: 12 hours</p>
            <p><a href="https://discord.com/invite/7Wm9Jg8MkW" target="_blank" class="button primary large">Join Discord</a></p>
        </div>
    </div>
    <div id="app" v-else>
        <div v-if="state.ethNetwork !== 1" class="testnet">You are on the Testnet</div>
        <div class="top-bar"></div>
        <div class="header">
            <div class="header-content">
        <span>
          <img class="logo" src="../../images/logo.svg" alt="StacksBridge">
          <span class="tag">v1.3.2</span>
        </span>
                <suspense>
                    <wallet></wallet>
                </suspense>
            </div>
        </div>
        <div class="content">
            <div class="page-width">
                <div v-if="state.isLoggedIn">
                    <div id="swap-header">
                        <div class="select">
                            <select v-model="state.currentProject">
                                <option v-for="project in Projects" :value="project">{{ project.name }}</option>
                            </select>
                            <span class="focus"></span>
                        </div>
                    </div>
                    <div id="swap-panel">
                        <suspense>
                            <template #default>
                                <div class="panel col">
                                    <EthereumPanel v-if="state.currentProject.name === 'Satoshibles'" />
                                    <MonstersEthereumPanel v-else />
                                </div>
                            </template>
                            <template #fallback>
                                <div class="panel loading col">
                                    <Spinner size="large" />
                                </div>
                            </template>
                        </suspense>
                        <div class="splitter col">
                            <Icon name="transfer" />
                        </div>
                        <suspense>
                            <template #default>
                                <div class="panel col">
                                    <StacksPanel v-if="state.currentProject.name === 'Satoshibles'" />
                                    <MonstersStacksPanel v-else />
                                </div>
                            </template>
                            <template #fallback>
                                <div class="panel loading col">
                                    <Spinner size="large" />
                                </div>
                            </template>
                        </suspense>
                    </div>
                </div>
                <div v-else class="page-note">
                    <div class="content" style="text-align: center;">
                        <h2>Welcome to StacksBridge</h2>
                        <p style="padding: 20px 0;">To begin you will need to login using both a <a href="https://metamask.io/download" target="_blank">MetaMask</a> wallet for Ethereum and
                            <a href="https://www.hiro.so/wallet" target="_blank">Hiro wallet</a> for Stacks. By using this bridge you are agreeing to the terms.</p>
                        <button class="button primary" @click="connect">Connect</button>
                    </div>
                </div>
                <div id="socials">
                    <a href="https://twitter.com/stacksbridge" target="_blank">
                        <Icon name="twitter" />
                    </a>
                    <a href="https://discord.gg/7Wm9Jg8MkW" target="_blank">
                        <Icon name="discord" />
                    </a>
                    <a href="https://etherscan.io/address/0x581a804895ccfee045f658a4fb7ecb2fbd5f6724" target="_blank">
                        <Icon name="etherscan" />
                    </a>
                    <a href="https://opensea.io/collection/satoshibles" target="_blank">
                        <Icon name="opensea" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <Modal v-if="state.showTerms" @close="state.showTerms = false">
        <div style="text-align: center;">
            <h2>Terms of Use</h2>
            <p>Before you start please make sure that you read and understand the General Terms of Use. The StacksBridge service is an early version of the product and not yet fully security audited and
                <strong>is not a fully trustless protocol</strong> at the moment. Please use at your own risk.</p>
            <p style="margin: 40px 0;"><a :href="`${baseUrl}stacksbridge-terms-of-use.pdf`" target="_blank">Download Full Terms of Use</a></p>
            <p>
                <button class="button primary" @click="state.showTerms = false">I Accept the Terms</button>
            </p>
        </div>
    </Modal>
</template>

<script>
import { Projects, StacksBridge, state } from '@/main';
import EthereumPanel from './EthereumPanel';
import MonstersEthereumPanel from '@/js/components/MonstersEthereumPanel';
import StacksPanel from './StacksPanel';
import Spinner from './Spinner';
import Icon from './Icon';
import Wallet from '@/js/components/Wallet';
import Modal from '@/js/components/Modal';
import MonstersStacksPanel from '@/js/components/MonstersStacksPanel';

export default {
    components: {
        MonstersStacksPanel,
        MonstersEthereumPanel,
        Modal,
        EthereumPanel,
        Wallet,
        StacksPanel,
        Spinner,
        Icon
    },
    setup() {
        const connect = () => {
            state.showConnectModal = true;
        };

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.has('bypass')) {
            state.maintenanceMode = false;
        }

        const baseUrl = StacksBridge.baseUrl;

        return {
            connect,
            state,
            baseUrl,
            Projects
        };
    }
};
</script>

<style lang="scss">
@import "../../css/app.scss";
</style>