import { state } from '@/main';
import { ethers } from 'ethers';

export const updateBalance = async () => {
    if (!state.isMetaMaskConnected) {
        return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    provider.getBalance(state.ethUser).then((balance) => {
        const amount = (ethers.utils.formatEther(balance)).toString();

        state.ethBalance = Number(Number(amount).toFixed(2));
    });
};
